@import "../../variables";

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.mid-size {
  #operation-wrapper {
    flex-direction: column;

    .operation-block {
      border-radius: 30px;
    }

    #operation-table {
      max-height: 310px !important;
      padding-bottom: 30px;
    }
  }
}

#operation-wrapper {
  display: flex;
  flex: 1;
  padding: 10px 10px 0 10px;
  height: 100%;
  background: #f2f5fe;

  .operation-block {
    margin: 10px 10px 0 10px;
    background: #fff;
    display: flex;
    flex-direction: column;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    overflow: hidden;

    #grid-operations {
      flex-grow: 1;
      height: 0;

      .oper-payment-type-column {
        display: flex;
        align-items: center;

        &_account {
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          align-items: flex-start;

          &__code {
            font-weight: bold;
          }

          &__name {
            display: flex;
            font-size: 12px;

            .account-currency {
              background: $main-blue;
              color: #fff;
              border-radius: 3px;
              padding: 0 3px;
              font-weight: bold;
            }
          }
        }

        .logo {
          &.flexipay {
            width: 30px;
            height: 34px;
            margin-bottom: -9px; //какая-то тень снизу
          }
        }

        .account-currency {
          margin-left: 5px;
        }
      }
    }

    .blank-block {
      margin: 106px 0;
      display: flex;
      flex-direction: column;
      text-align: center;

      .blank-block-logo-wrapper {
        width: 180px;
        margin: 30px auto 15px auto;
      }

      .blank-block-text {
        font-size: 20px;
        line-height: 30px;
        color: #000;
      }
    }
  }

  #operation-table {
    flex: 1;

    #grid-operations {

      .dx-datagrid-focus-overlay.dx-pointer-events-target {
        &:after {
          display: none;
        }
      }

      .dx-datagrid-adaptive-more {
        display: block;
      }

      .operations-grid-button {
        border-radius: 20px;
        background: #fff;
        box-shadow: 0 1px 7px 0 rgba(0, 120, 250, 0.2);
        outline: none;
        color: $grid-col-blue;

        &.convert-button {
          &.selected {
            background: $grid-col-blue;
            color: #fff;
          }
        }

        &.recharge-button {
          color: $grid-col-green;
          margin-left: 5px;

          &.selected {
            background: $grid-col-green;
            color: #fff;
          }
        }

        &.services-button {
          color: $grid-col-orange;
          margin-left: 5px;

          &.selected {
            background: $grid-col-orange;
            color: #fff;
          }
        }

        .dx-button-text {
          font-weight: bold;
          font-size: 12px;
          line-height: 12px;
          text-transform: none;
        }
      }
    }
  }

  #operation-form {
    flex: 1;

    .operation-form-wrapper {
      height: 100%;

      .transaction-screen-wrapper {
        height: 100%;

        .transaction-screen-fields {
          height: calc(100% - 70px);
          overflow: auto;
        }
      }
    }

    .hidden {
      display: none;
    }

    .top {
      height: 70px;
      align-items: center;
      display: flex;
      border-bottom: 1px solid $border-color;

      .head-string {
        margin: 0 5px 0 14px;
      }

      #operations-logo {
        margin-top: 15px;
      }

      .text-bold {
        font-weight: bold;
      }

      .currency-block-code {
        padding: 4px;
        background: $grid-col-green;
        color: #fff;
        border-radius: 3px;
        font-weight: bold;
      }

      span {
        margin: 0 5px;
      }

      .top-payment-name {
        margin: 0 5px 0 0;
      }
    }

    .operations-row {
      &:not(:first-child) {
        border-top: 1px solid $border-color;
      }

      &.operations-row-services {
        border: none;
      }

      .operations-row-inner {
        margin: 0 20px;

        .operations-title {
          margin: 13px 0 0 0;
          font-weight: bold;
        }

        .operations-fields-wrapper {
          margin-bottom: 5px;

          .checkbox-field {
            align-items: center;

            .checkbox-text {
              margin-left: 12px;
              font-size: 10px;
            }
          }
        }

        .operations-subtitle {
          margin-top: 21px;
          font-size: 15px;
          font-weight: bold;
        }
      }
    }

    .radio-select-wrapper {
      display: flex;
      margin: 13px 0 20px 0;

      .radio-select {
        position: relative;
        margin-right: 20px;
        display: flex;
        align-items: center;

        .radio-select-readonly {
          background: #f4f4f4;
          border-radius: 20px;
        }

        .dx-selectbox {
          box-shadow: 0 4px 27px 0 rgba(0, 0, 128, 0.1) !important;
          border-radius: 20px !important;
        }

        /*.dx-dropdowneditor-active {
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }*/

        .radio-select-checker, .radio-select-icon {
          position: absolute;
          top: 50%;
          margin-top: -10px;
          color: $grid-col-green;
          font-size: 20px;
          line-height: 20px;
        }

        .mdi-checkbox-blank-circle {
          position: absolute;
          top: 50%;
          left: 15px;
          margin-top: -4.5px;
          font-size: 10px;
          line-height: 9px;
          color: $grid-col-green;
        }

        .radio-select-checker.radio-select-checker-active {
          color: $grid-col-green;
        }

        .radio-select-checker {
          color: #EFEFF4;
          left: 10px;
        }

        .radio-select-icon {
          left: 35px;
        }
      }

      .dx-texteditor.dx-editor-outlined {
        box-shadow: none;
      }

      .dx-texteditor.dx-editor-filled .dx-texteditor-input, .dx-texteditor.dx-editor-outlined .dx-texteditor-input {
        padding: 12px 0 13px 60px;
      }
    }

    .method-select-wrapper {
      margin: 13px 0 20px 0;

      .dx-selectbox {
        box-shadow: 0 4px 27px 0 rgba(0, 0, 128, 0.1) !important;
        border-radius: 20px !important;
      }

      .dx-texteditor.dx-editor-outlined {
        box-shadow: none;
      }

      .dx-texteditor.dx-editor-filled .dx-texteditor-input, .dx-texteditor.dx-editor-outlined .dx-texteditor-input {
        padding: 12px 0 13px 14px;
      }

      .dx-placeholder {
        &:before {
          padding: 12px 0 13px 14px !important;
        }
      }
    }

    .operations-fieldset {
      margin: 29px 0 20px 0;
      display: flex;
      flex-direction: column;

      &.dense {
        margin: 12px 0 20px 0;
      }

      .operations-field-buttons {
        margin-bottom: 20px;
      }

      &.smooth-top {
        margin: 29px 0 15px 0;
      }

      &.smooth-center {
        margin: 0;
      }

      &.smooth-bottom {
        margin: 15px 0 20px 0;
      }

      .operations-field-full {
        width: 100%;
        display: flex;
        align-items: center;

        .operations-field-label {
          color: #8e8e93;
        }

        .operation-selectbox-currency.dx-state-readonly {
          //background: #f2f2f2;
          opacity: 0.48;
          color: rgba(0, 0, 0, 0.5);

          input, .operation-selectbox-currency_icon {
            color: rgba(0, 0, 0, 0.5) !important;
          }
        }

        .operation-selectbox-currency {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: 25px;
          background: #fff;
          box-shadow: 0 4px 27px 0 rgba(0, 0, 128, 0.08);
          padding-right: 20px;

          .operation-selectbox_input {
            width: 100%;

            .operation-selectbox_left {
              position: relative;
              display: flex;
              width: 100%;
              justify-content: space-between;
              align-items: baseline;
              padding: 0 10px 0 23px;

              &.operation-selectbox_left__read_only:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: calc(100% - 9px);
                height: 100%;
                //background-color: #f4f4f4;
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;
                opacity: 0.48;
              }

              .operation-total-amount {
                display: flex;
                align-items: baseline;
                width: 100%;

                &.currency-read-only {
                  color: rgba(0, 0, 0, 0.5);

                  .dx-texteditor-input {
                    color: rgba(0, 0, 0, 0.5) !important;
                  }
                }

                .operation-total-amount-input {
                  margin-left: 5px;

                  &:after {
                    border-bottom: none;
                  }
                }

                .operation-total-amount-input.dx-state-readonly {
                  margin: 0 5px;
                  width: 100%;

                  &:after {
                    border-bottom: none;
                  }
                }

                .operation-total-amount-input.dx-state-focused,
                .operation-total-amount-input.dx-invalid {
                  &:before {
                    border: none;
                  }
                }
              }

              .operation-fee-detail {
                padding-right: 13px;
                color: #8e8e93;
                font-weight: bold;
                min-height: 18px;
                flex-shrink: 0;
              }
            }
          }

          .operation-selectbox-wrapper {
            position: relative;
            display: flex;

            &:before {
              content: '';
              background: rgba(0, 0, 128, 0.08);
              position: absolute;
              top: 50%;
              margin-top: -13px;
              left: -10px;
              height: 26px;
              width: 2px;
            }

            .operation-selectbox-currency_icon {
              position: absolute;
              top: 50%;
              margin-top: -14px;
              left: 0;
              font-size: 18px;
              line-height: 30px;
              color: #007aff;
            }

            .operation-selectbox {
              .dx-dropdowneditor-input-wrapper.dx-selectbox-container {
                padding-left: 25px;
              }
            }

            .operation-selectbox.dx-texteditor.dx-editor-underlined:after,
            .operation-selectbox.dx-texteditor.dx-editor-underlined.dx-state-focused:before {
              border: none;
            }
          }
        }
      }

      .field-value-currency.currency-read-only, .field-value-currency-disabled {
        opacity: 0.48;
        color: rgba(0, 0, 0, 0.5);
      }

      .field-value-currency-display {
        display: none;
      }

      .field-value-currency {
        position: absolute;
        top: 50%;
        margin-top: -6px;
        line-height: 12px;
        font-size: 12px;
        left: 23px;
        z-index: 1;
      }

      .field-value-icon {
        position: absolute;
        top: 50%;
        margin-top: -10px;
        line-height: 20px;
        font-size: 20px;
        left: 10px;
        z-index: 1;
        color: $grid-col-green;
      }

      .details-field {
        margin-top: 20px;
        flex: 1;
      }

      .operations-field {
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }

        .operation-input.recipient-name {
          .dx-texteditor-input {
            text-transform: uppercase;
          }
        }

        display: flex;
        flex: 1 1 0;

        .dx-invalid-message > .dx-overlay-content {
          padding: 0 23px;
        }

        .field-value-relative {
          position: relative;

          .dx-texteditor.dx-editor-outlined .dx-texteditor-input, .dx-texteditor.dx-editor-outlined .dx-placeholder:before {
            padding: 12px 0 13px 35px;
          }

          .operations-field-addons {
            color: #8e8e93;
            font-weight: bold;
            min-height: 18px;
            flex-shrink: 0;
            position: absolute;
            right: 44px;
            top: 10px;
          }
        }

        .dx-field-label {
          white-space: normal;
          width: 100px;
          color: #000000DE !important;
        }

        .dx-field-value {
          min-width: 100px;
          flex: 1 1 auto;

          .operation-input {
            input.dx-texteditor-input {
              border-radius: 20px;
            }
          }
        }
      }

      .dx-texteditor.dx-editor-outlined .dx-texteditor-input, .dx-texteditor.dx-editor-outlined .dx-placeholder:before {
        padding: 12px 0 13px 23px;
      }

      .dx-texteditor.dx-editor-outlined.dx-state-readonly {
        //box-shadow: none;
        //background: #f8f8f8;
        opacity: 0.48;
      }

      .dx-texteditor.dx-editor-outlined {
        border-radius: 20px;
        box-shadow: 0 4px 27px 0 rgba(0, 0, 128, 0.1);
        //box-shadow: 0 4px 27px 0 rgba(0, 0, 128, 0.08);
      }
    }

    .operations-buttons {
      .buttons-wrapper {
        margin: 40px 0;
        display: flex;
        justify-content: center;

        .dx-textbox.dx-texteditor {
          .dx-texteditor-input {
            height: 36px;
            padding: 0 34px !important;
          }
        }

        .operations-fieldset {
          margin: 0;

          .otp-icon {
            font-size: 20px;
            line-height: 20px;
            position: absolute;
            top: 50%;
            left: 8px;
            z-index: 19;
            margin-top: -10px;
            color: #4cd964;
          }

          .resend-otp {
            position: absolute;
            top: 50%;
            margin-top: -10px;
            right: 8px;
            display: inline-block;
            width: 20px;
            height: 20px;
            background-image: url("/images/arrows.svg");
            background-color: white;
            background-repeat: no-repeat;
            background-size: 20px;
            cursor: pointer;
            border: none;

            &.rotating {
              animation: rotating 2s linear infinite;
            }

            &.disabled {
              cursor: default;
              opacity: 0.5;
            }
          }
        }

        .dx-placeholder {
          width: 100%;

          &:before {
            width: 100%;
            //text-align: center;
          }
        }

        .dx-button {
          box-shadow: 0 4px 27px 0 rgba(0, 0, 128, 0.1);
          margin: 0 20px;
          border-radius: 20px;

          .dx-button-content {
            .dx-button-text {
              text-transform: none;
              font-weight: bold;
            }

            white-space: normal;
            text-overflow: unset;
          }
        }
      }
    }
  }

  #operation-form {
    .swift-payout-fields {
      .operations-fieldset {
        .operations-field {
          .dx-field-label {
            width: 130px;
          }
        }
      }
    }
  }
}

#main-content {
  height: 100%;

  #operation-wrapper {
    .dx-texteditor-input, .dx-placeholder, .dx-item.dx-list-item {
      font-size: 12px;
    }

    .dx-texteditor.dx-editor-filled .dx-placeholder:before, .dx-texteditor.dx-editor-outlined .dx-placeholder:before {
      padding: 12px 0 13px 60px;
    }

    .dx-field-value-last {
      width: 90%;
    }


    .dx-button-has-text {
      .dx-button-content {
        padding: 0;

        .dx-button-text {
          padding: 5px 10px;
        }
      }
    }
  }
}

.dx-item-content.dx-list-item-content {
  font-size: 12px;
}

.dx-overlay-content {
  /*//box-shadow: 0 25px 40px -10px #c2ccea !important;
  box-shadow: 0 25px 40px -10px rgba(51, 51, 51, 0.27) !important;
  //box-shadow: 0 8px 14px -8px rgba(0, 0, 128, 0.1) !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;*/

  border-radius: 12px !important;
  //box-shadow: 0 25px 40px 15px rgba(51, 51, 51, 0.27) !important;
  box-shadow: 0 4px 27px 0 rgba(0, 0, 128, 0.1) !important;

  &.dx-invalid-message-content {
    box-shadow: none !important;
  }
}

.text-security input {
  font-family: text-security-disc, sans-serif !important;
}

.with-select-wrapper {
  display: flex;

  .operation-input {
    flex: 1;
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .select-currency-wrapper {
    position: relative;

    .operation-selectbox-currency_icon {
      position: absolute;
      top: 50%;
      margin-top: -9px;
      left: 10px;
      font-size: 18px;
      line-height: 18px;
      color: #007aff;
    }
  }

  .dx-selectbox {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;

    &:after {
      content: '';
      background: rgba(0, 0, 128, 0.08);
      position: absolute;
      top: 50%;
      margin-top: -13px;
      left: -1px;
      height: 26px;
      width: 2px;
      opacity: 0.48;
    }
  }
}

.dx-list-group {
  .dx-list-group-header {
    font-size: 14px;
  }
}

.group-wrapper {
  display: flex;
  align-items: center;

  &-income {
    color: $grid-col-green;
  }

  i {
    font-size: 20px;
    margin-right: 5px;
  }
}

.select-template-item {
  display: flex;
  align-items: center;

  &.recipient-template-item {
    height: 16px;

    .item-wrapper {
      display: flex;

      .item-currency {
        margin-right: 5px;
      }
    }
  }

  .image-wrapper {
    display: flex;
    align-items: center;
    margin-right: 10px;

    img {
      width: 12px;
    }
  }
}

.template-item-field {
  display: flex;
  margin-left: 15px;
  height: 40px;

  .image-wrapper {
    display: flex;
    align-items: center;
    height: 100%;

    img {
      width: 12px;
    }
  }

  .item-wrapper {
    .dx-texteditor-input {
      padding: 12px 0 13px 10px !important;
    }
  }
}

.recipient-item-field {
  font-size: 12px;
  display: flex;
  align-items: center;
  height: 40px;
  margin-left: 15px;

  .item-field-inner {
    display: flex;
    align-items: center;

    .image-wrapper {
      display: flex;
      margin-right: 10px;

      img {
        width: 12px;
      }
    }

    .item-currency {
      margin-right: -18px;
    }
  }

  .custom-value {
    .dx-texteditor-input {
      padding-left: 10px !important;
    }
  }
}

.regular-mask-value {
  .dx-texteditor-input {
    padding: 12px 0 13px 0 !important;
  }
}

@import './operations-crypto';

@import './lg';
