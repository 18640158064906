.req-wrapper {
  .req-inner {
    margin: 12px;
    padding: 20px;
    border-radius: 20px;
    background: #f9f9f9;
  }

  .req-title {
    font-size: 22px;
    font-weight: bold;
    color: #333;
    margin-bottom: 12px;
    display: inline-block;
  }

  .req-row {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #333;
    margin-bottom: 10px;

    .req-name {
      font-weight: bold;
    }

    .req-value {
      display: flex;
    }
  }

  .divider {
    background: #D9D9D9;
    height: 1px;
    margin: 20px 0;
  }
}