@media (max-width: 1199px) {
	#operation-wrapper {

		#operation-form {

			.operations-row {
				margin: 0 10px;
			}

			.operations-fieldset {
				align-items: stretch;

				.operations-field-full {
					.operation-selectbox-currency {
						padding-right: 0;
					}
				}

				.operations-field {
					flex-direction: column;
					align-items: stretch;


					.dx-field-label {
						width: auto;
						flex: 1 0 auto;
						align-self: stretch;
						padding-left: 15px;
						padding-bottom: 3px;
					}

					.dx-field-value {
						width: auto;
						position: relative;
					}
				}
			}
		}
	}
}
