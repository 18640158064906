.start-verification {
	height: 100%;
	padding: 20px;

	.verification-panel {
		width: 339px;
		border-radius: 12px;
		box-shadow: 0 10px 36px 0 rgba(0, 122, 255, 0.3);
		padding: 19px 20px 18px 20px;

		.verification-title {
			font-weight: bold;
			color: #000;
		}

		.verification-description {
			margin: 5px 0 13px 0;
			color: #8e8e93;

			.verification-account {
				color: #000;
			}
		}

		.verification-start {
			cursor: pointer;
			color: #007aff;
			font-weight: bold;
			text-decoration: underline;
		}
	}
}
