@import "./themes/generated/variables.base.scss";
@import "~@mdi/font/css/materialdesignicons.css";
@import "./variables";

@font-face {
  font-family: text-security-disc;
  src: url(assets/fonts/text-security-disc.woff);
}

.app {
  font-family: 'Montserrat', sans-serif;
  font-size: $appTextSize;
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  &.unauthorized {
    height: 100vh;
    flex-direction: row;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .adaptive-block-left {
      display: flex;
      flex: 1;
      height: 100%;
      background: #F1F5FF;
      padding: 20px;
      align-items: center;
      justify-content: center;

      @media (max-width: 768px) {
        display: none;
      }
    }

    .adaptive-block-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100vh;
      padding: 20px;
      overflow: auto;
      max-height: 100%;
      max-width: 100%;

      .login-form {
        font-size: 14px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        max-width: 512px;
        //background: #eef2f6; // Keep or change as needed

        .middle-block {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: start;
          flex-grow: 1;
        }

        .bottom-block {
          text-align: center;
          margin-top: auto; // Pushes the block to the bottom
          padding: 20px 0;

          .language-switcher {
            justify-content: center;
            display: flex;
            gap: 17px;
            margin: 8px 0 20px 0;

            .language-item {
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 4px;
              padding: 8px 12px;
              height: 36px;
              box-sizing: border-box;
              border-radius: 4px;
              border: 1px solid $light-grey;

              &.selected {
                border: 1px solid $ui-primary;

                span {
                  color: $ui-primary;
                }
              }

              img {
                width: 20px;
                height: 20px;
              }

              span {
                color: $dark-grey;
              }
            }
          }
        }

        .form-title {
          color: $light90;
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
        }

        .secondary-text {
          color: $light80;
          font-weight: 500;
          margin: 0;
        }

        .link {
          font-weight: 600;
          color: $ui-primary;
        }
      }
    }

    .round-button-wrapper {
      width: 100%;
      height: unset;

      .round-button-wrapper_button {
        width: 100% !important;
        margin-top: 28px;

        &.new-button {
          background: $ui-primary;

          .dx-button-text {
            color: white;
            font-weight: 600;
          }

          &.dx-state-hover {
            background: $ui-primary-pressed;

            .dx-button-text {
              color: white;
            }
          }

          &.dx-state-focused {
            box-shadow: none;
            background: $ui-primary-pressed;

            .dx-button-text {
              color: white;
            }
          }

          &.active {
            background: $ui-primary;

            .dx-button-text {
              color: white;
            }
          }
        }

        &.dx-button {
          box-shadow: unset;
        }
      }
    }
  }

  .dx-button-mode-outlined {
    border: none;
  }
}

.forgot-password {
  text-align: right;
  width: 100%;
}

#root {
  .dx-checkbox-icon {
    border-radius: 4px;

    &:after {
      width: 30px;
      height: 30px;
      margin-top: -15px;
      margin-left: -15px;
    }
  }

  .wrapper-class-name-popup {
    width: 60px;
  }

  .add-walletto-checkbox {
    display: flex;
    align-items: center;
    margin: 30px 0;

    #agreement-checkbox-text {
      padding: 7px 17px 7px 20px;
    }

    .dx-checkbox-icon {
      border-color: $main-blue;
    }

    .dx-checkbox-checked .dx-checkbox-icon {
      color: $main-blue;
      border: 2px solid $main-blue;
    }
  }
}

.content {
  background-color: $acc-background;
}

.dx-datagrid-headers, .dx-treelist-headers {
  .dx-header-row {
    .dx-datagrid-action, .dx-treelist-action {
      //text-align: center !important;
      font-weight: bold !important;
    }
  }
}

.recent-operation {
  .recent-operation-text {
    font-size: $appTextSize;
    margin-right: 7px;
  }
}


.dx-card.wide-card {
  margin: 0;

  .dx-button-content {
    .dx-icon.mdi {
      color: $base-accent;
    }
  }
}

.recent-operation {
  color: $base-accent;
  font-weight: bold;
}

.grid-col-red {
  color: $grid-col-red;
}

.grid-col-green {
  color: $grid-col-green;
}

.col-red-underline, .col-green-underline, .col-orange-underline {
  position: relative;
  padding-bottom: 5px;
}

.col-red-underline:after, .col-green-underline:after, .col-orange-underline:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
}

.col-red-underline:after {
  background-color: $grid-col-red;
}

.col-green-underline:after {
  background-color: $grid-col-lightgreen;
}

.col-orange-underline:after {
  background-color: $grid-col-orange;
}

.dx-row-focused .grid-col-red {
  color: $badge-color;
}

.dx-data-row td {
  //text-align: center !important;
}

.utc {
  color: #8e8e93;
}

#grid-acc-statement {
  flex: 1 1 0;
  flex-basis: 0;
  margin-top: 0;
  box-shadow: none;

  .dx-datagrid-table .dx-row > td {
    padding: 9px 0;
    font-size: $appTextSize;
    line-height: 15px;
  }

  .dx-datagrid-header-panel {
    border-bottom: none;
  }

  .dx-datagrid-headers {
    color: #333;
  }

  .dx-row.dx-header-row {
    td {
      height: 30px;
      padding: 0;
      line-height: 18px;
    }
  }
}

#merchant-accounts, #accounts-sharpay, #accounts-walletto {
  .dx-datagrid-headers, .dx-datagrid-header-panel {
    border: none;

    tr {
      background: $grey-text-bg;
    }
  }

  .dx-datagrid-table .dx-row, .dx-header-row {
    height: 48px;
    box-sizing: border-box;
    border: none;

    &:nth-child(even) {
      background: $grey-text-bg;
    }
  }

  .dx-datagrid-table .dx-row > td {
    font-size: 14px;
    //line-height: 48px;
    white-space: initial;
    padding: 0 14px;
    box-sizing: border-box;
    border: none;
  }
}

#operation-table {
  .dx-datagrid-table .dx-row > td {
    padding: 10px;
  }
}

#top-menu {
  .dx-menu-item-selected {
    background: none;
    font-weight: bold;
  }

  .dx-state-hover {
    background: none;
  }
}

#tile-view, #accounts {
  font-size: $appTextSize;
}

.under-construction-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  background: #fff;
  justify-content: center;
  align-items: center;

  .under-construction {
    max-width: 1440px;
    padding: 40px;

    img {
      width: 100%;
    }
  }
}

.full-height {
  height: auto;
  flex: 1 1 0;
  overflow: hidden;
  position: relative;
}

.dx-tagbox-popup-wrapper {
  .dx-overlay-content {
    .dx-scrollview-content {
      .dx-list-select-all {
        .dx-list-select-all-checkbox {
          margin-left: 10px;
        }

        .dx-list-select-all-label {
          font-size: 12px;
          line-height: 14px;
          margin: 0;
          padding: 0 15px;
        }
      }
    }

    .dx-list-item {
      .dx-list-item-before-bag.dx-list-select-checkbox-container {
        width: 40px;

        .dx-list-select-checkbox {
          margin-left: 10px;
        }
      }

      .dx-list-item-content {
        padding: 14px 3px 13px;
      }
    }
  }
}

.dx-datagrid-header-panel {
  .dx-toolbar {
    .dx-item {
      .dx-icon {
        color: $main-blue;
      }
    }
  }
}

.value-with-icon {
  display: flex;

  *:first-child {
    margin-right: 5px;
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.with-copy-button {
  display: flex;

  .copy-button-wrapper {
    flex: 0;
  }
}

.copy-button {
  width: 14px;
  height: 16px;
  display: inline-block;
  background: url(/images/icons/file-copy-24-px.svg) 0 0 no-repeat;
  cursor: pointer;
  margin-left: 7px;
  position: relative;

  .copy-hint {
    opacity: 0;
    transition: opacity 1s ease-out;
    font-size: 12px;
    padding: 3px 6px;
    color: #fff;
    position: absolute;
    left: 0;
    bottom: -31px;
    background-color: #4cd964;
    border: 1px solid #4cd964;
    border-radius: 6px;

    &.copy-hint-visible {
      opacity: 1;
    }

    &:before {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      top: -5px;
      left: 3px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #4cd964;
    }
  }
}

.password-recovery-component {
  padding: 24px;

  .round-input-wrapper {
    width: 442px;
  }

  .round-button-wrapper {
    width: 250px;
  }
}

#root {
  .app-popup-fields-inner {
    .round-input-wrapper {
      margin-bottom: 14px;
    }
  }
}

@media all and (max-width: 720px) {
  #root {
    #login-page {
      padding: 0 10px;

      .dx-overlay-wrapper {
        display: block;

        .password-recovery-component {
          .round-input-wrapper {
            width: 100%;
          }

          .round-button-wrapper {
            width: 100%;
          }
        }
      }
    }

    .dx-overlay-wrapper {
      display: none;
    }
  }
}

.no-touch {
  pointer-events: none;
}


.dx-texteditor.dx-editor-outlined.dx-state-disabled .dx-texteditor-input, .dx-texteditor.dx-editor-outlined.dx-state-readonly .dx-texteditor-input, .dx-texteditor.dx-editor-outlined.dx-state-readonly.dx-state-hover .dx-texteditor-input {
  color: #333 !important;
}

.animated-page {
  animation: fade-in 1s;
}

.animated-popup {
  animation: fade-in 500ms;
}

.popup-show {
  animation: popout 250ms ease;
}

@keyframes popout {
  from {
    transform: scale(0)
  }
  to {
    transform: scale(1)
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

.banxe-fields {
  p {
    margin: 5px;
  }
}

.dx-button {
  &.round-toolbar-button {
    border-radius: 18px;
    color: white;
    background-color: $main-blue;

    &.dx-state-hover, &.dx-state-focused {
      background-color: #2094EFFF;
    }

    .dx-button-content {
      .dx-icon {
        color: white;
      }

      .dx-button-text {
        text-transform: none;
      }
    }
  }
}

.app-popup-inner {
  height: 100%;

  .app-popup-head {
    .app-popup-title {
      font-size: 18px;
      font-weight: bold;
    }
  }

  .app-popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    line-height: 20px;

    i {
      font-size: 20px;
      color: #d7d7d7;
    }
  }

  .app-popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    height: 100%;
  }

  .app-popup-fields-wrapper {
    width: 100%;
    height: 100%;
    overflow-y: auto;

    .app-popup-fields-inner {
      padding: 20px 20px 0 20px;

      .inp-label {
        line-height: 1.8;
        color: #4D5256;
      }

      .dx-texteditor {
        &.dx-editor-outlined {
          .dx-texteditor-input {
            padding: 0 12px 0 50px;
          }

          .dx-placeholder {
            padding: 0 12px 0 38px;

            &:before {
              margin-top: 1px;
              padding: 0 0 0 12px;
            }
          }
        }

        &.dx-editor-underlined {
          .dx-texteditor-input {
            padding: 19px 12px 18px 35px;
          }

          .dx-placeholder {
            padding: 0 12px 0 30px;
          }
        }
      }

      .app-popup-select-wrapper {
        .flexi-popup-select_item {
          position: relative;
          margin-bottom: 14px;

          .flexi-popup-select_item__icon {
            width: 19px;
            position: absolute;
            top: 50%;
            left: 21px;
            margin-top: -10px;
          }

          .dx-tagbox {
            padding: 0 20px !important;

            /*.dx-tag-container {
              input.dx-texteditor-input {
                display: none!important;
              }
            }*/
          }

          &.is-selected {
            .dx-tagbox {
              padding: 10px 20px !important;

            }
          }
        }

        .dx-selectbox.dx-textbox, .dx-selectbox.dx-textbox.dx-state-focused {
          padding: 0 20px;
          border-radius: 25px;
          box-shadow: 0 4px 27px 0 rgba(0, 0, 128, 0.08);

          &:after {
            border: none;
          }
        }

        .dx-texteditor-input, .dx-placeholder {
          padding-left: 35px;
        }
      }
    }
  }
}

.padded-text {
  padding: 20px;
  width: 100%;
}

.with-copy-button-row {
  display: flex;
  gap: 7px;
}

.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td {
  padding-right: 7px;
  padding-left: 7px;
}

.button-group-wrapper {
  .dx-buttongroup-item.dx-button.dx-item-selected.dx-button-normal {
    background: transparent;
  }
}

#ctx-menu {
  position: absolute;
  width: auto;
  height: auto;
  background: white;
  opacity: 0;
  z-index: -1;
  border-radius: 12px;
  box-shadow: 0 4px 27px 0 rgba(0, 0, 128, .1);
  padding: 20px;

  &.visible {
    z-index: 11;
    opacity: 1;
  }

  .column-operation-button {
    cursor: pointer;
    display: flex;
    padding: 4px 10px;
    gap: 5px;

    &:not(:last-child) {
      border-bottom: 1px solid #EDEFF2;
    }

    .ctx-blue-item {
      color: $main-blue;
    }

    &.invisible {
      display: none;
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &:hover {
      background-color: rgba(0, 0, 0, .08);
    }
  }
}

#ctx-wrapper {
  width: 100%;
  height: 100%;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  z-index: 10;

  &.visible {
    display: block;
  }
}

.text-wrap {
  overflow-wrap: break-word;
  overflow: hidden;
  width: 100%;
  display: inline-block;
}

.last-item {
  margin-bottom: 30px;
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.full-width {
  width: 100%;
}

.popup-buttons {
  display: flex;
  gap: 20px;

  &.to-right {
    justify-content: end;
  }

  .dx-button {
    border-radius: 20px !important;
  }
}

.payment-detail-wrapper .payment-detail-wrapper-inner .payment-detail-close i.close-black {
  color: #1D1F20;
}

.group-id {
  color: $main-blue;
}

.detail-widget {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000033;
  z-index: 100;

  .detail-widget-inner {
    font-size: 14px;
    position: absolute;
    top: 0;
    right: 0;
    width: 450px;
    height: 100%;
    background: white;
    z-index: 101;
    overflow: hidden;

    .detail-content {
      overflow-y: auto;
      height: 100%;

      .status {
        display: inline-block;
      }

      .detail-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 68px;
        padding: 0 20px;

        .header-title, .close-black {
          font-size: 20px;
        }

      }

      .close-black, .item-with-content {
        cursor: pointer;
      }

      .detail-fields-wrapper {
        .load-wrapper {
          text-align: center;
        }

        .item {
          flex-wrap: wrap;
          display: flex;
          justify-content: space-between;
          align-items: center;
          min-height: 48px;
          padding: 0 20px;

          &.item-with-content {
            padding: 16px 20px;
          }

          .sub-item {
            margin: 13px 20px 0 20px;
            flex-basis: 100%;

            .sub-item-row {
              align-items: center;
              min-height: 48px;
              display: flex;
              justify-content: space-between;
            }
          }

          &:nth-child(odd) {
            background: $grey-text-bg;
          }

          .item-label {
            color: $def60;
            margin-right: 10px;
            flex: 1 1 130px;
          }

          .item-value {
            text-align: right;
            color: black;
            overflow: hidden;
            text-overflow: ellipsis;

            &.flexi {
              display: flex;
              gap: 5px;

              .group-id {
                font-size: 21px;
                line-height: 1;
              }
            }
          }
        }
      }
    }
  }
}

.card-widget-wrapper {
  width: 340px;
  max-height: 300px;
  overflow: hidden;

  .card-widget-inner {
    height: 100%;
    overflow-y: auto;
    padding: 0 10px;
    //box-sizing: content-box;
  }
}


.card-widget {
  width: 100%;
  margin: 8px 0;
  background: #FFF;
  padding: 20px;
  border: 1px solid $def-light-20;
  border-radius: 16px;
  box-shadow: 0 0 16px 0 #3A42470A;

  .row-delimiter {
    height: 1px;
    background: $def-light-20;
    width: 100%;
    margin: 12px 0;
  }

  .card-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .card-row-group {
      display: flex;
      gap: 4px;
      align-items: center;
    }

    .card-row-bottom-group {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
    }

    .group-delimiter {
      width: 1px;
      height: 20px;
      background: $def-light-20;
      margin: 0 11px;
    }
  }

  .collapsable-row {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
  }

  .collapsable-row.expanded {
    max-height: 49px;
  }
}

.chevron {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 18px;
  transition: transform 0.3s ease;
}

.chevron.up {
  transform: rotate(-180deg);
}

.horizontal-bar-widget {
  &:after {
    content: '';
    clear: none;
  }
}

.bar-widget {
  height: 60px;
  background: #FFF;
  border-top-right-radius: 23px;
  border-top-left-radius: 23px;

  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  cursor: grab;
  user-select: none;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */

  .account-inner {
    display: flex;
    align-items: center;
    width: fit-content;
    min-width: 100%;
    height: 100%;
    border-bottom: 2px solid $acc-background;
    padding: 0 5px;
  }

  &:focus-visible {
    outline: none;
  }
}

.bar-widget::-webkit-scrollbar {
  display: none; /* WebKit */
}

.account-item {
  display: inline-block;
  width: 300px;
  height: 100%;
  margin: 0 20px;
  box-sizing: border-box;
  white-space: nowrap;
  position: relative;

  .account-item-border {
    height: 2px;
    background: $acc-background;
  }

  &.selected:after {
    content: '';
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: -2px;
    left: 0;
    background: $main-blue;
  }
}

.account-widget {
  height: 100%;
  display: flex;
  flex-direction: column;

  .account-row {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .account-row-group {
      display: flex;
      gap: 4px;
      align-items: center;
    }
  }
}

.bar-widget:active {
  cursor: grabbing;
}

.account-detail-wrapper {
  z-index: 4;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;

  .account-detail {
    width: 340px;
    z-index: 5;
    //border-top: 1px solid $border-color;
    //border-radius: 16px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    background: #fff;

    &:before {
      width: 100%;
      height: 100%;
      content: '';
      position: absolute;
      top: -52px;
      left: 0;
      background: transparent;
      border-radius: 16px;
      padding-top: 52px;
      box-shadow: 0 25px 40px 0 #c2ccea;
      pointer-events: none;
      //box-shadow: 0 25px 40px 0 green;
    }
  }

  .account-detail-inner {
    border-top: 1px solid $border-color;
  }

  .inner-top {
    position: absolute;
    left: 0;
    top: -50px;
    width: 100%;
    height: 50px;
    z-index: 3;

    .close-cursor {
      position: absolute;
      width: 20px;
      height: 20px;
      right: 20px;
      top: 10px;
      opacity: 0.2;
      cursor: pointer;
      z-index: 4;
    }
  }
}

.card-item-wrapper {
  margin: 12px 20px;
}

.account-currency {
  background: $main-blue;
  color: #fff;
  border-radius: 3px;
  padding: 3px;
  font-weight: 700;
  font-size: 12px;
}

.outer {
  height: 100px;
  overflow: hidden;

  .inner {
    height: inherit;
    overflow: auto;
  }
}

/* From pages/main/account-details/styles.scss - remove it later */
.buttons-container {
  display: flex;
  justify-content: center;
  position: relative;

  .green, .blue {
    width: 50%;

    .dx-button-text {
      text-transform: none;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
    }
  }

  .blue {
    border-radius: 0 0 0 12px;
    color: $texteditor-focused-border-color;
  }

  .green {
    border-radius: 0 0 12px 0;
    color: $grid-col-lightgreen;
  }

  .divider {
    position: absolute;
    height: 28px;
    top: 50%;
    margin-top: -14px;
    left: 50%;
    margin-left: -1px;
    width: 2px;
    background: $acc-background;
  }
}

.tile-acc-wrap {
  display: flex;
  align-items: center;
  padding: 8px 20px;
  border-bottom: 1px solid $border-color;

  .tile-text {
    .tile-acc-amount {
      margin-right: 10px;
    }
  }

  .tile-text-detail {
    justify-content: space-between;

    &.without-icon-gap {
      margin-left: 26px;
    }

    .tile-acc-settings {
      display: flex;
      flex: 1;
      justify-content: flex-end;

      .card-settings-btn {
        margin-right: 5px;
        cursor: pointer;
        font-size: 14px;
      }
    }
  }

  &.tile-acc-currency-wrap {
    position: relative;
    width: 100%;
    padding-right: 0;
  }
}

.tile-text {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  font-size: 12px;

  .card-icon {
    margin: 0 7px 0 0;
    display: inline;
    height: 20px;

    svg {
      width: 20px;
      height: 100%;

      path {
        stroke: $texteditor-focused-border-color;
      }
    }
  }

  .tile-acc-icon {
    color: $texteditor-focused-border-color;
    font-size: 20px;
    margin: 0 5px 0 0;
  }

  .tile-acc-number {
    background: url("/images/system-update-alt-24-px.svg") no-repeat center;
    background-size: 16px 16px;
    width: 20px;
    height: 20px;

  }

  .tile-acc-label {
    line-height: 1 !important;
  }

  .tile-acc-num, .tile-acc-bold {
    line-height: 20px;
  }

  .flex-1 {
    flex: 1;
  }

  .tile-vision {
    width: 14px;
    margin-right: 5px;
    cursor: pointer;
  }

  .vision-loader {
    margin-right: 5px;
  }
}

.same-height {
  height: 14px;
  cursor: pointer;
}

.card-settings {
  height: 20px;
  cursor: pointer;
}

.tile-acc-bold {
  font-weight: bold;
  margin-right: 5px;
}

.card-lock {
  color: red;
}

.walletto-card-wrap {
  margin: 8px 20px;
  display: flex;
  justify-content: space-between;

  .walletto-card-item {
    display: flex;
  }
}

.field-mode-buttons {
  display: flex;
  gap: 8px;
}

.policy-agreement {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
}

.checkbox-input {
  min-width: 20px;
  min-height: 20px;
}

.registration-agreement-text {
  span {
    font-weight: 400;
    color: $light70;
  }

  a {
    font-weight: 500;
  }
}

.interactive-hint {
  color: $light90;
  margin-bottom: 12px;
}

.info-panel {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  //max-height: 76px;
  border: 1px solid $main-yellow;
  border-radius: 8px;
  background: $secondary-yellow;
  //overflow: hidden;

  /*.info-panel-text {
    height: 100%;
    overflow: auto;
  }*/
}

.phone-input-item {
  display: flex;
  align-items: center;
  gap: 8px;
}