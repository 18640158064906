.detail-settings-wrapper {

	.dx-selectbox {
		padding: 0 20px;
		border-radius: 25px;
		box-shadow: 0 4px 27px 0 rgba(0, 0, 128, 0.08);
		margin-bottom: 20px;

		&:after {
			display: none;
		}
	}

	.round-input-wrapper {
		.round-input-wrapper_input {
			.dx-texteditor-container {
				.dx-texteditor-input-container {
					.dx-texteditor-input {
						padding: 0 20px;
					}

					.dx-placeholder {
						&:before {
							padding: 3px 20px 0 20px;
						}
					}
				}
			}
		}
	}
}
