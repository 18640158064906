.crypto-item {
	display: flex;
	height: 15px;
	align-items: center;

	.image-wrapper {
		min-width: 15px;

		img {
			height: 15px;
		}
	}

	.crypto-item-wrapper {
		display: flex;
		align-items: center;
		flex: 1;

		.left {
			margin: 0 10px;
			min-width: 170px;

			.left-top {
				.left-top-bold {
					font-weight: bold;
				}

				.left-top-margin {
					margin-left: 5px;
				}
			}
		}

		.right {
			font-weight: bold;
		}
	}
}

.crypto-item-field {
	display: flex;
	align-items: center;

	.image-wrapper {
		height: 15px;
		line-height: 15px;
		min-width: 28px;

		img {
			height: 15px;
			margin-left: 15px;
		}
	}

	.field-wrapper {
		flex: 1;
		align-items: center;
		height: 40px;
		display: flex;
		font-size: 12px;

		.left {
			min-width: 170px;
			margin: 0 10px;

			.left-bold {
				font-weight: bold;
				margin-right: 5px;
			}
		}

		.right {
			font-weight: bold;
		}
	}

	.crypto-display-text {
		.dx-texteditor-input {
			padding-left: 10px !important;
		}
	}
}
