@import "variables";

#root {
	display: flex;
	flex-direction: column;
	height: 100%;

	.dx-popup-content {
		//padding: 0;
		position: relative;

		.flexi-popup-close {
			position: absolute;
			top: 10px;
			right: 10px;
			cursor: pointer;
			width: 20px;
			height: 20px;
			line-height: 20px;

			i {
				font-size: 20px;
				color: #d7d7d7;

				&:hover {
					color: $border-color;
				}
			}
		}

		.otp-popup-content {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 0 0 25px 0;
			height: 100%;

			.flexi-popup-title {
				font-size: 20px;
				margin: 20px 0;
			}

			.otp-popup-component {
				.component-subtitle {
					margin-bottom: 10px;
					text-align: center;
					font-size: 14px;
					color: #8e8e93;
				}
			}
		}

		.flexi-popup-content {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 0 20px 33px 20px;
			height: 100%;

			&.app-popup {
				.flexi-popup-component {
					padding: 24px;
				}
			}

			.flexi-popup-logo {
				width: 120px;
				margin: 30px 0 15px 0;
				text-align: center;
			}

			.flexi-popup-head {
				text-align: center;
				margin-bottom: 10px;

				.flexi-popup-title {
					font-size: 18px;
					font-weight: bold;
				}
			}

			.flexi-popup-component {
				width: 100%;

				.password-recovery-component {
					display: flex;
					flex-direction: column;
					align-items: center;
					margin-bottom: 10px;

					.component-subtitle {
						margin-bottom: 20px;
						font-size: 12px;
						color: #8e8e93;
					}
				}
			}
		}
	}
}
