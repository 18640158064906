.login-tab {
	width: 50%;
	height: 58px;
	padding: 12px 16px;
	min-width: 90px;
	font-size: 20px;
	color: #333;
	outline: none;
	border: none;
	background: none;
	position: relative;
	cursor: pointer;
	overflow: hidden;
	transition: background 400ms;


	&.tab-selected {
		&:before {
			content: "";
			position: absolute;
			bottom: 0;
			height: 2px;
			left: 0;
			right: 0;
			background: #4cd964;
		}
	}
}

span.ripple {
	position: absolute;
	border-radius: 50%;
	transform: scale(0);
	animation: ripple 600ms linear;
	background-color: rgba(0,0,0,.16);;

}

@keyframes ripple {
	to {
		transform: scale(4);
		opacity: 0;
	}
}
