.walletto-fields {
	.card-holder {
		.card-holder-block {
			display: flex;
			align-items: center;
			margin-bottom: 30px;

			.card-holder-label {
				flex: 1 0 50%;
			}

			.card-holder-text {
				flex: 1 0 50%;
			}
		}
	}

	.round-input {
		.dx-texteditor-container {
			.dx-texteditor-input-container {
				.dx-placeholder {
					&:before {
						padding: 16px 20px 18px;
					}
				}
			}
		}

		.dx-invalid-message {
			&.dx-overlay {
				&.dx-widget {

					.dx-overlay-wrapper {
						&.dx-invalid-message {

							.dx-overlay-content {
								&.dx-invalid-message-content{
									padding: 0 20px !important;
									box-shadow: none !important;
								}
							}
						}
					}

				}
			}
		}

		.dx-texteditor-input {
			margin-left: 20px;
		}
	}

	.terms-link {
		font-weight: bold;
	}
}
