.mobile-plug-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-bottom: 60px;

	.mobile-plug-text {
		font-size: 20px;
		margin: 20px;
	}

	.mobile-plug-app {
		text-align: center;

		a {
			cursor: pointer;
			margin: 0 5px;
			display: inline-block;

			img {
				height: 55px;
			}
		}
	}
}