#root {
  .round-input-wrapper {
    position: relative;
    margin-bottom: 20px;

    &.round-otp {
      width: 442px;
    }

    .round-input-icon {
      font-size: 24px;
      line-height: 24px;
      position: absolute;
      top: 50%;
      left: 20px;
      z-index: 19;
      margin-top: -12px;
      color: #4cd964;
    }

    .round-input-wrapper_input {
      border-radius: 25px;
      box-shadow: 0 4px 27px 0 rgba(0, 0, 128, 0.08);

      &:before {
        border: none;
      }

      .dx-texteditor-input {
        border-radius: 25px;
        background: #fff;
      }
    }
  }

  .flexi-popup-round_input__icon {
    position: absolute;
    z-index: 3;
    top: 50%;
    margin-top: -10px;
    left: 20px;
  }
}
