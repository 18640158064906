.mid-size {
	#settings-wrapper {
		flex-direction: column;

		.settings-block {
			border-radius: 30px;
			min-height: 336px;
		}
	}
}

#settings-wrapper {
	display: flex;
	flex: 1 1;
	padding: 10px 10px 0 10px;
	height: 100%;
	background: #f2f5fe;

	.settings-block {
		margin: 10px 10px 0 10px;
		background: #fff;
		display: flex;
		flex-direction: column;
		border-top-left-radius: 30px;
		border-top-right-radius: 30px;
		overflow: hidden;
	}

	#settings-category {
		display: flex;
		flex-direction: column;
		background: #fff;
		color: #333;
		flex: 1 1;

		.settings-category:last-child {
			border: none;
		}

		.settings-category {
			display: flex;
			cursor: pointer;
			min-height: 112px;
			padding: 20px;
			border-bottom: 1px solid #e0e0e0;

			.settings-category_icon {
				display: flex;
				align-items: center;
				width: 70px;
				height: 70px;
				background: #fff;
				border-radius: 12px;
				box-shadow: 0 25px 40px 0 #c2ccea;
				margin-right: 20px;

				i {
					text-align: center;
					width: 70px;
					font-size: 30px;
					color: #4cd964;
				}
			}

			.settings-category_desc {
				.settings-category_title {
					font-weight: bold;
					font-size: 18px;
					line-height: 18px;
					margin-bottom: 7px;
				}
			}
		}

		.active-category {
			background: #efeff4;
			border: none;
		}
	}

	#settings-fields {
		background: #fff;
		flex: 1 1 250px;
	}
}