.currency-input-wrapper {
	&.read-only {
		.currency-input {
			opacity: 0.48;
			color: rgba(0, 0, 0, 0.5);
		}
	}

	.currency-input {
		box-shadow: 0 4px 27px 0 rgba(0, 0, 128, 0.1);
		border-radius: 20px;
		display: flex;
		align-items: center;

		.currency-input-value-wrapper {
			height: 100%;
			padding: 0 0 0 24px;
			display: flex;
			align-items: center;
			color: inherit;

			.currency-input-value {
				color: inherit;
			}
		}

		.operation-input {
			box-shadow: none !important;
			opacity: 1 !important;
		}
	}

	.operation-input {
		flex: 1;
		border-top-left-radius: 0 !important;
		border-bottom-left-radius: 0 !important;

		.dx-texteditor-input {
			padding: 12px 0 13px 5px !important;
		}

		.dx-invalid-message {
			margin-left: -10px;

			.dx-overlay-wrapper {
				.dx-overlay-content {
					padding: 0 0 0 5px !important;
					border-radius: 0 !important;
				}
			}
		}
	}
}
