#invite-wrapper {
	.invite-inner {
		padding: 16px 20px;

		.invite-field {
			margin-bottom: 20px;

			.invite-input {
				display: flex;
				align-items: center;
				background: #f4f4f4 !important;
				box-shadow: none !important;

				.invite-copy {
					width: 14px;
					height: 16px;
					display: inline-block;
					background: url(/images/icons/file-copy-24-px.svg) 0 0 no-repeat;
					cursor: pointer;
					margin: 0 10px;
					position: relative;

					.invite-hint {
						opacity: 0;
						transition: opacity 1s ease-out;
						font-size: 12px;
						padding: 3px 6px;
						color: #fff;
						position: absolute;
						left: 0;
						bottom: -31px;
						background-color: #4cd964;
						border: 1px solid #4cd964;
						border-radius: 6px;

						&.copy-hint-visible {
							opacity: 1;
						}

						&:before {
							content: '';
							width: 0;
							height: 0;
							position: absolute;
							top: -5px;
							left: 3px;
							border-left: 5px solid transparent;
							border-right: 5px solid transparent;
							border-bottom: 5px solid #4cd964;
						}
					}
				}

				.input-text {
					font-weight: bold;
					word-break: break-word;
				}
			}

			.invite-input label {
				display: flex;
				cursor: pointer;
				position: relative;
				padding-left: 25px;
				margin-right: 0;
				line-height: 18px;
				height: 18px;
				user-select: none;
			}

			.invite-label {
				font-family: Montserrat, sans-serif;
				font-size: 12px;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: normal;
				color: #323232;
				font-weight: 600;
				margin-bottom: 18px;
			}

			.invite-text {
				font-family: Montserrat, sans-serif;
				font-size: 12px;
				font-weight: 500;
				color: #333;
				padding: 11px 13px;
				border-radius: 20px;
				box-shadow: 0 4px 27px 0 rgba(0, 0, 128, 0.08);
				background-color: #fff;
				margin-bottom: 18px;

				a {
					word-wrap: break-word;
					text-decoration: none;
					color: #333;
				}
			}
		}
	}
}
