@import "variables";

.payment-detail-wrapper {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	overflow: hidden;

	.payment-detail-wrapper-inner {
		height: 100%;

		.load-wrapper {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.payment-detail-close {
			position: absolute;
			top: 15px;
			right: 15px;
			cursor: pointer;
			width: 20px;
			height: 20px;
			line-height: 20px;

			i {
				font-size: 20px;
				color: #d7d7d7;

				&:hover {
					color: $border-color;
				}
			}
		}

		.payment-detail-title {
			position: absolute;
			top: 15px;
			left: 20px;
			font-size: 16px;
			font-weight: bold;
		}

		.payment-detail-body {
			margin-top: 80px;
			height: calc(100% - 88px);

			.payment-detail-inner {
				font-size: 12px;
				height: 100%;
				overflow-y: auto;

				.payment-detail-item {
					min-height: 50px;
					padding: 10px 20px;
					display: flex;
					align-items: center;
					justify-content: space-between;

					&:nth-child(odd) {
						background: $grey-text-bg;
					}

					.item-label {
						color: $label-color;
						margin-right: 10px;
						flex: 1 1 130px;
					}

					.item-value {
						text-align: right;
						color: $text-color;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}
			}
		}
	}
}