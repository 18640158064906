$base-font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
$base-label-color: rgba(0, 0, 0, 0.87);
$base-link-color: #007aff;
$base-icon-color: rgba(0, 0, 0, 0.54);
$base-spin-icon-color: rgba(0, 0, 0, 0.54);
$base-text-color: rgba(0, 0, 0, 0.87);
$base-bg: #fff;
$base-border-color: #e0e0e0;
$base-border-radius: 4px;
$base-success: #8BC34A;
$base-warning: #FFC107;
$base-danger: #F44336;
$base-hover-color: rgba(0, 0, 0, 0.87);
$base-hover-bg: #f5f5f5;
$base-focus-color: #fff;
$base-focus-bg: #cccccc;
$badge-color: #fff;
$badge-bg: #007aff;
$texteditor-color: rgba(0, 0, 0, 0.87);
$texteditor-placeholder-color: rgba(148, 148, 148, 0.87);
$texteditor-bg: rgba(0, 0, 0, 0.04);
$texteditor-focused-bg: rgba(0, 0, 0, 0.04);
$texteditor-border-color: rgba(0, 0, 0, 0.42);
$texteditor-hover-border-color: rgba(0, 0, 0, 0.87);
$texteditor-focused-border-color: #007aff;
$textbox-search-icon-color: rgba(0, 0, 0, 0.54);
$accordion-title-color: rgba(0, 0, 0, 0.87);
$accordion-item-border-color: #e0e0e0;
$accordion-icon-active-color: rgba(0, 0, 0, 0.87);
$button-normal-color: rgba(0, 0, 0, 0.87);
$button-normal-bg: #fff;
$button-normal-hover-bg: #ebebeb;
$button-normal-focused-bg: #e0e0e0;
$button-normal-active-bg: #e0e0e0;
$button-default-color: #fff;
$button-default-bg: #007aff;
$button-default-hover-bg: #0066d6;
$button-default-focused-bg: #005dc2;
$button-default-active-bg: #003f85;
$button-danger-color: #fff;
$button-danger-bg: #F44336;
$button-danger-hover-bg: #f21f0f;
$button-danger-focused-bg: #e11b0c;
$button-danger-active-bg: #e11b0c;
$button-success-color: #fff;
$button-success-bg: #8BC34A;
$button-success-hover-bg: #76ab39;
$button-success-focused-bg: #6c9c34;
$button-success-active-bg: #6c9c34;
$button-flat-color: rgba(0, 0, 0, 0.87);
$drawer-shader-background-color: rgba(0, 0, 0, 0.5);
$fileuploader-filename-color: rgba(0, 0, 0, 0.87);
$fileuploader-falename-status-color: rgba(102, 102, 102, 0.87);
$fileuploader-border-color: #e0e0e0;
$gallery-indicator-bg: #fff;
$gallery-indicator-item-selected-bg: #007aff;
$gallery-indicator-focused-bg: #007aff;
$gallery-navbutton-hover-color: #007aff;
$gallery-navbutton-active-color: #007aff;
$list-group-color: rgba(0, 0, 0, 0.87);
$list-group-header-bg: rgba(255, 255, 255, 0);
$list-item-selected-bg: #e0e0e0;
$list-item-active-bg: #cccccc;
$overlay-content-bg: #fff;
$overlay-shader-bg: rgba(0, 0, 0, 0.32);
$popup-title-bg: transparent;
$dropdowneditor-icon-active-color: rgba(0, 0, 0, 0.54);
$selectbox-list-bg: #fff;
$tabs-tab-color: rgba(0, 0, 0, 0.54);
$tabs-tab-selected-color: #007aff;
$tabs-selected-tab-bottom-border-color: #007aff;
$tabs-tab-bg: #f7f7f7;
$tabs-hovered-tab-bg-color: #ededed;
$tagbox-tag-color: rgba(0, 0, 0, 0.6);
$tagbox-tag-bg: #e0e0e0;
$tagbox-tag-active-color: rgba(0, 0, 0, 0.87);
$tagbox-tag-button-remove-bg: rgba(0, 0, 0, 0.36);
$navbar-tab-color: rgba(0, 0, 0, 0.87);
$navbar-tab-selected-color: rgba(0, 0, 0, 0.87);
$navbar-tab-bg: #f7f7f7;
$navbar-tab-selected-bg: #fff;
$scrollable-scroll-bg: rgba(191, 191, 191, 0.7);
$toolbar-bg: #fff;
$toast-color: rgba(255, 255, 255, 0.87);
$toast-info-bg: rgba(0, 0, 0, 0.87);
$toast-warning-bg: #FFC107;
$toast-error-bg: #F44336;
$toast-success-bg: #8BC34A;
$load-indicator-segment-bg-color: #007aff;
$loadpanel-content-bg: #fff;
$material-slider-bar-bg: rgba(0, 122, 255, 0.24);
$material-slider-bg: #007aff;
$material-slider-tooltip-color: #fff;
$progressbar-bg: rgba(0, 122, 255, 0.3);
$progressbar-range-bg: #007aff;
$progressbar-label-color: #007aff;
$switch-bg: #9e9e9e;
$switch-handle-off-bg: #fafafa;
$switch-on-bg: rgba(0, 122, 255, 0.5);
$switch-handle-on-bg: #007aff;
$pager-page-selected-color: #fff;
$pager-page-selected-bg: #007aff;
$colorbox-overlay-bg: #fff;
$datagrid-columnchooser-item-color: rgba(0, 0, 0, 0.54);
$datagrid-columnchooser-font-weight: 500;
$datagrid-drag-header-shadow-color: rgba(0, 0, 0, 0.1);
$datagrid-selection-bg: rgba(0, 122, 255, 0.04);
$datagrid-row-selected-border-color: #e0e0e0;
$datagrid-row-selected-color: rgba(0, 0, 0, 0.87);
$datagrid-row-focused-color: #fff;
$datagrid-row-focused-bg: #007aff;
$datagrid-menu-icon-color: rgba(86, 86, 86, 0.54);
$datagrid-cell-modified-border-color: rgba(139, 195, 74, 0.32);
$datagrid-row-invalid-faded-border-color: rgba(244, 67, 54, 0.32);
$datagrid-group-row-color: rgba(0, 0, 0, 0.54);
$datagrid-group-row-bg: #ffffff;
$datagrid-search-color: #fff;
$datagrid-search-bg: #007aff;
$datagrid-row-error-color: #fff;
$datagrid-row-error-bg: #f88e86;
$datagrid-focused-border-color: #007aff;
$datagrid-editor-bg: rgba(255, 255, 255, 0);
$datagrid-row-alternation-bg: #f5f5f5;
$datagrid-summary-color: rgba(0, 0, 0, 0.7);
$PIVOTGRID_AREA_COLOR: rgba(0, 0, 0, 0.54);
$PIVOTGRID_TOTALCOLOR: rgba(221, 221, 221, 0.2);
$PIVOTGRID_GRANDTOTALCOLOR: #f5f5f5;
$PIVOTGRID_FIELD_AREA_TEXT_COLOR: rgba(0, 0, 0, 0.3);
$SCHEDULER_BASE_BORDER_COLOR: #e0e0e0;
$SCHEDULER_ACCENT_BORDER_COLOR: #e0e0e0;
$SCHEDULER_APPOINTMENT_TEXT_COLOR: #fff;
$SCHEDULER_APPOINTMENT_BASE_COLOR: #007aff;
$SCHEDULER_APPOINTMENT_START_COLOR: rgba(0, 0, 0, 0.3);
$SCHEDULER_WORKSPACE_FOCUSED_CELL_COLOR: rgba(0, 122, 255, 0.12);
$SCHEDULER_FIRST_MONTH_BORDER_COLOR: #e0e0e0;
$SCHEDULER_CURRENT_TIME_CELL_COLOR: #007aff;
$menu-color: rgba(117, 117, 117, 0.87);
$menu-item-selected-bg: rgba(224, 224, 224, 0.87);
$calendar-header-color: rgba(102, 102, 102, 0.87);
$calendar-cell-other-color: rgba(125, 125, 125, 0.87);
$calendar-cell-selected-bg: #007aff;
$calendar-shevron-icon-color: #007aff;
$tooltip-color: #fff;
$tooltip-bg: #616161;
$tileview-color: rgba(0, 0, 0, 0.87);
$treeview-focused-bg: #f5f5f5;
$form-group-border-color: #e0e0e0;
$filterbuilder-text-color: rgba(0, 0, 0, 0.87);
$filterbuilder-text-focus-color: #FFF;
$filterbuilder-plus-icon-color: rgba(139, 195, 74, 0.7);
$filterbuilder-remove-icon-color: rgba(244, 67, 54, 0.7);
$filterbuilder-group-operation-color: rgba(244, 67, 54, 0.3);
$filterbuilder-item-field-color: rgba(0, 122, 255, 0.3);
$filterbuilder-item-operator-color: rgba(139, 195, 74, 0.3);
$filterbuilder-item-value-color: rgba(224, 224, 224, 0.5);
$base-accent: #007aff;
$radiogroup-checked-bg: #007aff;
$radiogroup-border-color: rgba(0, 0, 0, 0.54);
$radiogroup-bg: #fff;
$accordion-color: rgba(0, 0, 0, 0.87);
$accordion-background-color: #fff;
$accordion-title-active-color: rgba(0, 0, 0, 0.87);
$accordion-item-hover-bg: #f5f5f5;
$accordion-title-active-bg: #f5f5f5;
$checkbox-border-color: rgba(0, 0, 0, 0.54);
$checkbox-bg: #007aff;
$checkbox-hover-border-color: #005dc2;
$base-invalid-color: #F44336;
$base-invalid-faded-border-color: rgba(244, 67, 54, 0.4);
$list-border-color: #e0e0e0;
$list-normal-color: rgba(0, 0, 0, 0.87);
$list-item-hover-bg: #f5f5f5;
$list-item-focused-selected-bg: rgba(204, 204, 204, 0.7);
$navbar-tab-focused-border-color: #cccccc;
$datagrid-base-color: rgba(0, 0, 0, 0.87);
$datagrid-base-background-color: #fff;
$datagrid-border-color: #e0e0e0;
$datagrid-hover-bg: #f2f2f2;
$datagrid-row-hovered-color: rgba(0, 0, 0, 0.87);
$datagrid-nodata-color: rgba(102, 102, 102, 0.87);
$datagrid-link-color: #007aff;
$datagrid-columnchooser-bg: #fff;
$treelist-base-color: rgba(0, 0, 0, 0.87);
$treelist-base-background-color: #fff;
$treelist-border-color: #e0e0e0;
$treelist-columnchooser-item-color: rgba(0, 0, 0, 0.54);
$treelist-columnchooser-font-weight: 500;
$treelist-selection-bg: rgba(0, 122, 255, 0.04);
$treelist-row-selected-border-color: #e0e0e0;
$treelist-row-selected-color: rgba(0, 0, 0, 0.87);
$treelist-row-focused-color: #fff;
$treelist-row-focused-bg: #007aff;
$treelist-menu-icon-color: rgba(86, 86, 86, 0.54);
$treelist-cell-modified-border-color: rgba(139, 195, 74, 0.32);
$treelist-row-invalid-faded-border-color: rgba(244, 67, 54, 0.32);
$treelist-search-color: #fff;
$treelist-search-bg: #007aff;
$treelist-row-error-color: #fff;
$treelist-row-error-bg: #f88e86;
$treelist-focused-border-color: #007aff;
$treelist-editor-bg: rgba(255, 255, 255, 0);
$treelist-row-alternation-bg: #f5f5f5;
$treelist-summary-color: rgba(0, 0, 0, 0.7);
$treelist-hover-bg: #f2f2f2;
$treelist-row-hovered-color: rgba(0, 0, 0, 0.87);
$treelist-nodata-color: rgba(102, 102, 102, 0.87);
$treelist-link-color: #007aff;
$treelist-columnchooser-bg: #fff;
$PIVOTGRID_BORDER_COLOR: #e0e0e0;
$PIVOTGRID_DATA_AREA_COLOR: rgba(0, 0, 0, 0.87);
$scheduler-cell-hover-bg: #f5f5f5;
$SCHEDULER_PANEL_TEXT_COLOR: rgba(0, 0, 0, 0.54);
$SCHEDULER_WORKSPACE_BACKGROUND_COLOR: #fff;
$SCHEDULER_WORKSPACE_ACCENT_COLOR: #007aff;
$menu-popup-bg: #fff;
$menu-item-hover-bg: #f5f5f5;
$menu-popup-border-color: #e0e0e0;
$calendar-color: rgba(0, 0, 0, 0.87);
$calendar-hover-bg: #f5f5f5;
$calendar-cell-active-bg: rgba(96, 96, 96, 0.2);
$treeview-hover-bg: #f5f5f5;
$treeview-border-color: #e0e0e0;
$form-field-item-color: rgba(117, 117, 117, 0.87);
$filterbuilder-bg: #fff;
$datagrid-chevron-icon-color: rgba(98, 98, 98, 0.54);
$datagrid-filter-row-background-color: #fafafa;
$datagrid-active-header-filter-icon-color: #007aff;
$datagrid-filter-panel-color: #007aff;
$treelist-chevron-icon-color: rgba(98, 98, 98, 0.54);
$treelist-filter-row-background-color: #fafafa;
$treelist-active-header-filter-icon-color: #007aff;
$treelist-filter-panel-color: #007aff;
$pivotgrid-chevron-icon-color: rgba(0, 0, 0, 0.54);
$menu-item-expanded-color: rgba(117, 117, 117, 0.87);
$numberbox-spin-icon-color: rgba(0, 0, 0, 0.54);
$treeview-spin-icon-color: rgba(0, 0, 0, 0.54);
