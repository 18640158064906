@import "../../themes/generated/variables.base.scss";
@import "../../dx-styles.scss";

.header-component {
	flex: 0 0 auto;
}

.header-menu-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 70px;
	position: relative;
	background: #f2f5fe;

	&:after {
		content: '';
		width: 100%;
		height: 3px;
		background: #fff;
		position: absolute;
		bottom: 0;
		left: 0;
	}

	.language-content {
		height: 30px;

		.dx-texteditor.dx-editor-underlined {
			background: #ffffff;
			border-radius: 15px;
		}

		.dx-texteditor.dx-editor-underlined:after {
			border: none;
		}

		.language-flag-wrapper {
			.language-flag {
				margin: 5px;
			}
		}
	}

	.top-menu-wrapper {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: space-evenly;

		a {
			height: 100%;
			line-height: 70px;
			color: #333;
			text-decoration: none;
			display: flex;
			justify-content: center;
			align-items: center;

			i {
				width: 36px;
				height: 36px;
				display: inline-block;
				background-repeat: no-repeat;
				background-position: center;
			}
		}

		a.selected {
			position: relative;
			font-weight: bold;
		}

		a.selected:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 3px;
			background: #007aff;
			z-index: 2;
		}

		a {
			i {
				background-size: contain;
			}
		}

		#main {
			i {
				background-image: url("/images/menu-icons/accounts.svg");
			}
		}

		#transactions {
			i {
				background-image: url("/images/menu-icons/merchant.svg");

			}
		}

		#operations {
			i {
				background-image: url("/images/menu-icons/operations.svg");
			}
		}

		#settings {
			i {
				background-image: url("/images/menu-icons/settings.svg");
			}
		}
	}

	.left-content {
		.top-logo-wrapper {
			padding: 0 20px;
		}
	}

	.center-content {
		height: 100%;
		flex-grow: 1;
		margin: 0 100px;
	}

	.right-content {
		padding-top: 15px;
		height: 100%;
	}

}

#top-menu {
	.dx-menu-item-text a, .dx-menu-item-content a {
		color: #333;
		text-decoration: none;
	}

	.dx-menu-items-container {
		display: flex;
		justify-content: space-evenly;
	}

}

.language-flag-wrapper {
	display: flex;
	align-items: center;

	.language-flag {
		margin-right: 5px;
	}

	.language-name {
		.dx-texteditor.dx-editor-underlined {
			.dx-texteditor-input {
				cursor: pointer;
				font-size: 12px;
			}
		}

		.dx-texteditor.dx-editor-underlined.dx-state-readonly:after {
			border: none;
		}
	}
}
