@import "../../themes/generated/variables.base.scss";

.user-info {
	height: 100%;
	background-color: rgba(194, 204, 234, 0.3);
	border-radius: 35px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.dx-toolbar-menu-section & {
		padding: 10px 6px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}

	.image-container {
		overflow: hidden;
		border-radius: 50%;
		height: 30px;
		width: 30px;
		/*margin: 0 4px;
		border: 1px solid rgba(0, 0, 0, 0.1);*/
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);

		.user-image {
			width: 100%;
			height: 100%;
			background: url("https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/06.png") no-repeat #fff;
			background-size: cover;
		}
	}

	.user-name {
		font-size: 12px;
		line-height: 12px;
		color: $base-text-color;
		//margin-left: 10px;
		margin-left: 20px;
	}
}

.user-panel {
	height: 30px;

	.dx-list-item .dx-icon {
		vertical-align: middle;
		color: rgba(0, 0, 0, 0.87);
		margin-right: 16px;
	}

	.dx-rtl .dx-list-item .dx-icon {
		margin-right: 0;
		margin-left: 16px;
	}
}

.dx-context-menu.user-menu.dx-menu-base {
	&.dx-rtl {
		.dx-submenu .dx-menu-items-container .dx-icon {
			margin-left: 16px;
		}
	}

	.dx-submenu .dx-menu-items-container .dx-icon {
		margin-right: 16px;
	}

	.dx-menu-item .dx-menu-item-content {
		padding: 3px 15px 4px;
	}
}

.dx-theme-generic .user-menu .dx-menu-item-content .dx-menu-item-text {
	padding-left: 4px;
	padding-right: 4px;
}

.user-name-icon {
	font-size: 24px;
	line-height: 24px;
	margin-right: 8px;
}

@mixin item-border {
	content: '';
	position: absolute;
	width: 100%;
	height: 1px;
	left: 0;
	background-color: #efeff4;
}

.header-menu-wrapper {
	.dx-overlay-wrapper {
		.dx-overlay-content {
			border-radius: 4px !important;

			.dx-popup-content {
				padding: 8px 0;
				border-radius: 4px;

				.user-panel-menu-item {
					font-size: 15px;
					height: 30px;
					position: relative;

					&.border-top {
						&:before {
							@include item-border;
							top: 0;
						}
					}

					&.border-bottom {
						&:before {
							@include item-border;
							bottom: 0;
						}
					}

					.user-panel-item-wrapper {
						height: 100%;

						.user-panel-item-icon {
							position: absolute;
							top: 50%;
							margin-top: -12px;
							left: 15px;
						}

						.user-panel-item {
							width: 100%;
							height: 100%;
							padding: 7px 15px;
							vertical-align:middle;
							display: inline-block;
							text-decoration: none;
							color: rgba(0, 0, 0, 0.87);
							cursor: pointer;

							&.item-with-icon {
								padding-left: 47px;
							}
						}
					}
				}
			}
		}
	}
}
