.sciapi-wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;

	.sciapi-inner {
		height: calc(100% - 72px);
		position: relative;

		.sciapi-inner-wrapper {
			height: 100%;

			.sciapi-inner-list {
				display: flex;
				flex-wrap: wrap;

				.merchant-block {
					min-width: 250px;
					width: 33%;
					margin: 20px;
					padding: 17px 20px 20px;
					border-radius: 15px;
					box-shadow: 0 25px 39px 0 rgba(194, 204, 234, 0.67);
				}

				.merchant-simple {
					display: flex;
					flex-direction: column;
					height: 100px;

					.round-button-wrapper {
						.round-button-wrapper_button {
							.dx-button-content {
								padding: 0;
							}
						}
					}
				}
			}

			.add-new {
				background: #ffffff;
				position: absolute;
				width: 78px;
				height: 78px;
				padding: 19px;
				border-radius: 20px;
				box-shadow: 0 25px 40px 0 #c2ccea;
				bottom: 20px;
				right: 20px;

				.plus-button{
					background: #4cd964;
					width: 40px;
					height: 40px;
					color: #fff;
					border-radius: 20px;
					text-align: center;
					line-height: 40px;
					font-size: 38px;
					cursor: pointer;
				}
			}
		}
	}
}


