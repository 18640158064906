@import "../../variables";

.main-wrapper {
  position: relative;
  height: 100%;

  .grid-acc-wrapper {
    position: relative;
    height: 100%;
  }
}

#flexi-main {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  margin: 0 20px;

  .grid-acc-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    overflow: hidden;
  }
}

.dx-datagrid-filter-row {
  .dx-menu {
    .dx-menu-horizontal {
      .dx-menu-items-container {
        left: 15px;
      }
    }
  }
}

#load-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
