$base-danger: #F44336;
$base-primary: #007aff;

.otp-input-stateful {
	.round-input-wrapper {
		padding: 5px 25px;

		.otp-component {
			display: flex;
			width: 100%;
			height: 50px;
			border-radius: 25px;
			background-color: white;
			align-items: center;
			padding: 0 20px;
			position: relative;

			.left-pocket {
				i {
					font-size: 24px;
					line-height: 1;
					color: #4cd964;
				}
			}

			&:before {
				left: 0;
				right: 0;
				bottom: 0;
				height: 0;
				width: 100%;
				content: "";
				z-index: 2;
				position: absolute;
				box-sizing: border-box;
				transform: scale(0);
			}

			&.with-resend {
				.right-pocket {
					.resend-otp-button {
						display: block;

						&:disabled {
							color: #7e7e7e;
						}
					}
				}
			}

			&.with-focused-state {
				&.otp-component-focused {
					&:before {
						border-bottom: 2px solid $base-primary;
						transform: scale(1);
						-webkit-transition: -webkit-transform .6s cubic-bezier(.4, 0, .02, 1);
						transition: -webkit-transform .6s cubic-bezier(.4, 0, .02, 1);
						transition: transform .6s cubic-bezier(.4, 0, .02, 1);
					}
				}
			}

			&.otp-component-focused {
				&.invalid {
					&:before {
						border-bottom: 2px solid $base-danger;
						transform: scale(1);
					}

					.validation-message {
						display: block;
					}
				}
			}

			.validation-message {
				display: none;
				position: absolute;
				bottom: -17px;
				left: 25px;
				font-size: .85em;
				color: $base-danger;
			}

			&.invalid {
				.right-pocket {
					.validation-sign {
						display: block;
					}
				}
			}

			.otp-input-wrapper {
				margin: 0 10px;
				width: 100%;
				height: 100%;

				input {
					padding: 5px;
					font-size: 12px;
					width: 100%;
					height: 100%;
					border: none;
					outline: none;

					&::placeholder {
						color: rgba(148, 148, 148, 0.87);
					}
				}
			}

			.right-pocket {
				position: relative;
				display: flex;
				align-items: center;

				.resend-otp-button {
					all: unset;
					display: none;
					cursor: pointer;
					font-size: 14px;
					color: $base-primary;
					font-weight: 600;
					margin: 0 5px;
					white-space: nowrap;
					border: none;
				}

				.validation-sign {
					display: none;
					position: relative;
					width: 24px;
					margin-right: 7px;

					&:after {
						color: white;
						display: inline-block;
						text-align: center;
						line-height: 24px;
						width: 24px;
						height: 24px;
						content: '!';
						background-color: $base-danger;
						font-size: 14px;
						pointer-events: none;
						border-radius: 50%;
					}
				}
			}
		}
	}

	.button-wrapper {
		display: flex;
		justify-content: space-between;
		padding: 5px 25px;

		.round-button-wrapper {
			&:first-child {
				margin-right: 5px;
			}

			&:last-child {
				margin-left: 5px;
			}
		}
	}
}

