@import "../../themes/generated/variables.base.scss";
@import "../../variables";

.merchant-tab-wrapper {
	display: flex;

	.merchant-tab-item {
		width: 200px;
		padding: 10px;
		margin: 0 10px;
		background: green;
		height: 35px;
	}
}

#main-content {
  position: relative;

	.dx-overlay-content {
		border-top-left-radius: 0 !important;
		border-top-right-radius: 0 !important;

		.dx-popup-content {
			padding: 15px 20px;
		}
	}

	.dx-layout-manager .dx-field-item {
		padding-left: 20px;
	}

	.column-operation-button {
		font-size: $appTextSize;
		font-weight: bold;
		cursor: pointer;
	}

	#grid-acc-statement {

		.dx-link {
			color: #333333;
			font-weight: 600;
			background-color: #F0F2F9;
			padding: 5px 10px;
			border-radius: 20px;
			font-size: $appTextSize;

			&:empty {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;

				&:before {
					font-family: "Material Design Icons", sans-serif;
					content: "\F0156";
				}
			}
		}

		.dx-icon {
			color: $base-accent;
		}
	}
}

#transaction-wrapper {
	margin: 20px 20px 0 20px;
}