.settings-section_inputs.security-input {
	.settings-section_edit {
		.dx-textbox.dx-texteditor {

			.dx-texteditor-input {
				padding: 0 44px 0 110px !important;
			}

			.dx-invalid-message > .dx-overlay-content{
				padding: 5px 20px;
			}
		}
	}
}
