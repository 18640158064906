.crypto-img {
	display: flex;
	align-items: center;
}

.crypto-account-number {
	.crypto-code {
		margin-bottom: 5px;
	}

	.bold-text {
		margin-right: 5px;
	}
}

.crypto-summary {
	display: flex;
	//margin-right: 20px;

	.crypto-qr-wrapper {

		.crypto-qr {
			padding: 20px;
		}
	}

	.crypto-fields {
		margin: 20px 0;
		flex: 1;

		.operations-fieldset {
			margin-top: 18px!important;
		}

		.crypto-field {
			margin-bottom: 20px;

			.crypto-input {
				display: flex;
				align-items: center;

				.input-text {
					word-break: break-word;
				}
			}

			.crypto-input input[type=radio] {
				display: none;
			}

			.crypto-input label {
				display: flex;
				cursor: pointer;
				position: relative;
				padding-left: 25px;
				margin-right: 0;
				line-height: 18px;
				height: 18px;
				user-select: none;
			}

			.crypto-input label:before {
				content: "";
				display: inline-block;
				width: 17px;
				height: 18px;
				position: absolute;
				left: 0;
				top: 50%;
				margin-top: -9px;
				background: url(/images/icons/radio-button-unchecked.svg) 0 1px no-repeat;
			}

			/* Checked */
			.crypto-input input[type=radio]:checked + label:before {
				background: url(/images/icons/radio-button-checked-24-px.svg) 0 1px no-repeat;
			}

			/* Disabled */
			.crypto-input input[type=radio]:disabled + label:before {
				filter: grayscale(100%);
			}

			.crypto-label {
				font-family: Montserrat, sans-serif;
				font-size: 12px;
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: normal;
				color: #8e8e93;
				margin-bottom: 18px;
			}

			.crypto-text {
				font-family: Montserrat, sans-serif;
				font-size: 12px;
				font-weight: 500;
				color: #333;
				padding: 11px 13px;
				border-radius: 20px;
				box-shadow: 0 4px 27px 0 rgba(0, 0, 128, 0.08);
				background-color: #fff;

				a {
					word-wrap: break-word;
					text-decoration: none;
					color: #333;
				}
			}
		}
	}
}

.crypto-currency-head {
	display: flex;
	align-items: center;

	.crypto-img {
		width: 30px;

		.account-icon {
			width: 100%;
		}
	}

	.crypto-currency {
		margin-left: 10px;
	}
}

.content-mobile {
	.crypto-summary {
		flex-direction: column;

		.crypto-qr {
			text-align: center;
		}

		.crypto-fields {
			padding: 0 20px;
		}
	}
}
