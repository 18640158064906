@import "../../../variables.scss";

.bottom-gap {
  padding-bottom: 5px;
}

.item-detail-wrap {
  padding: 8px 20px;
  border-top: 1px solid $border-color;
  display: flex;
  justify-content: space-between;
  gap: 5px;

  .item-text-bold {
    font-weight: bold;
  }
}