.legal-wrapper {
	height: 100%;

	.legal-inner {
		height: 100%;

		.load-wrapper {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.legal-section-wrapper {
			.legal-section {
				font-family: 'Montserrat', sans-serif;
				color: #333;
				padding: 16px 20px;

				.legal-title {
					font-size: 18px;
					font-weight: bold;
					margin-bottom: 25px;
				}

				.legal-subtitle {
					font-size: 16px;
					font-weight: 500;
					margin-bottom: 15px;
				}

				.legal-content {
					font-size: 14px;
					margin-bottom: 30px;
				}

				.legal-link {

				}
			}
		}
	}
}
