.edit-merchant-wrapper {
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	.edit-merchant-form {
		flex: 1;

		.edit-merchant-inner {
			padding: 20px 17px 20px 23px;

			.edit-merchant-title {
				margin-bottom: 10px;
				font-weight: bold;
			}

			.edit-merchant-inputs {
				.generate-input-wrapper {
					position: relative;

					.round-input-wrapper {
						.dx-texteditor-container {
							.dx-texteditor-input-container {
								.dx-placeholder {
									font-size: 12px;

									&:before {
										padding: 0 0 0 40px;
									}
								}
							}
						}
					}

					.generate-input-icon {
						font-size: 15px !important;
						margin-top: -10px !important;
					}

					.generate-block {
						display: flex;
						justify-content: flex-end;
						align-items: center;
						position: absolute;
						top: 0;
						right: 0;
						width: 138px;
						height: 100%;
						z-index: 1;
						margin-left: 10px;

						.generate-button {
							cursor: pointer;
							color: #0079fe;
							font-weight: 600;
							margin-right: 10px;
						}

						.generate-eye {
							margin-left: 10px;
							cursor: pointer;
							color: #33b84a;
							font-size: 18px;
						}
					}
				}

				.merchant-input {
					.dx-texteditor-container {
						.dx-texteditor-input-container {
							input {
								font-size: 12px;
								padding: 0 0 0 20px;
							}

							.dx-placeholder {
								font-size: 12px;

								&:before {
									padding: 0 0 0 20px;
								}
							}
						}
					}
				}

				.generate-input {
					&.merchant-input {
						.dx-texteditor-container {
							.dx-texteditor-input-container {
								input {
									padding: 0 148px 0 40px;
								}
							}
						}
					}
				}

				.round-selectbox {
					border-radius: 20px;
					//box-shadow: 0 4px 27px 0 rgba(0, 0, 128, 1);
					box-shadow: 0 4px 27px 0 rgba(0, 0, 128, 0.08);

					.dx-selectbox {
						&:after {
							display: none;
						}

						.dx-texteditor-container {
							padding: 0 20px;
						}
					}
				}
			}

			.edit-merchants-payment-systems {
				display: flex;

				.edit-payment-systems {
					max-width: 184px;
					border-radius: 12px;
					box-shadow: 0 4px 27px 0 rgba(0, 0, 128, 0.08);
					padding: 20px 30px;
					margin-right: 20px;

					.edit-payment-systems-head {
						display: flex;
						justify-content: center;
						align-items: center;

						.edit-payment-systems-name {
							margin-right: 5px;
						}
					}

					.ps-button {
						.dx-button-content {
							padding: 0;
						}
					}
				}
			}
		}
	}

	.edit-merchants-buttons-wrapper {
		height: 140px;
		display: flex;
		justify-content: center;

		.edit-merchants-buttons {
			width: 420px;
			height: 40px;
			display: flex;
			margin-top: 40px;
			justify-content: space-between;

			.edit-merchants-button {
				//margin: 10px 0;
			}
		}
	}
}


.payment-system-icon {
	width: 30px;
	height: 30px;
	display: block;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100%;
	margin: 5px 0;

	&.sharpay {
		background-image: url("/images/accounts/small-logo/SP_80_80_1.svg");
	}

	&.advcash {
		background-image: url("/images/accounts/small-logo/advcash.svg");
	}

	&.webmoney {
		background-image: url("/images/accounts/small-logo/webmoney.svg");
	}

	&.connectum {
		background-image: url("/images/accounts/small-logo/connectum.svg");
	}

	&.perfect {
		background-image: url("/images/accounts/small-logo/perfect.svg");
	}

	&.payeer {
		background-image: url("/images/accounts/small-logo/payeer.svg");
	}

	&.billline {
		background-image: url("/images/accounts/small-logo/billline.svg");
	}

	&.neteller {
		background-image: url("/images/accounts/small-logo/neteller.svg");
	}

	&.scrill {
		background-image: url("/images/accounts/small-logo/skrill.svg");
	}

	&.paypal {
		background-image: url("/images/accounts/small-logo/paypal.svg");
	}

	&.cardpay {
		background-image: url("/images/accounts/small-logo/cardpay-vector-logo.svg");
	}

	&.sharpay-card {
		background-image: url("/images/accounts/small-logo/SharPayCard.svg");
	}
}

/*#root {
	.dx-texteditor-container {
		.dx-texteditor-input-container {
			.dx-texteditor-input {
				font-size: 12px;
			}
		}
	}
	.dx-placeholder {
		font-size: 12px;
	}
}*/
