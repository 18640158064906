.charts-wrapper {
  background: white;
  padding: 25px;
  overflow: hidden;

  .chart-top-bar {
    display: flex;
    justify-content: flex-end;

    i {
      cursor: pointer;
    }
  }

  .charts-inner {
    margin: 25px;
    height: 100%;
    overflow: auto;

    .chart-item {
      padding: 25px;
      height: 100%;
      width: 50%;
    }
  }
}

@media screen and (min-width: 600px) {
  .charts-inner {
    display: flex;
    flex-wrap: wrap;
  }

  .chart-quarter {
    flex-basis: 50%;
    height: 50%;
  }
}