@import "variables";

.form-input {
  width: 100%;
  margin-bottom: 12px;

  .flex {
    display: flex;
    gap: 8px;

    .flex-1 {
      flex: 1;
    }

    .w-24 {
      flex: 0;
      min-width: 125px;

      .dx-dropdowneditor-field-template-wrapper {
        align-items: center;

        .phone-input-item {

          .dx-texteditor-input {
            padding: 8px 0;
          }

          img {
            margin-left: 15px;
          }
        }
      }
    }
  }

  .form-input-label {
    font-weight: 500;
    color: $light60;
    display: block;
    margin-bottom: 4px;
  }

  .dx-texteditor.dx-editor-outlined {
    border-radius: 100px;
    box-shadow: inset 0 0 0 1px $light30;

    .dx-texteditor-input {
      font-family: Montserrat, sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $light90;
    }

    .dx-texteditor-input, .dx-placeholder:before {
      padding: 8px 16px;
    }

    &.dx-state-hover {
      box-shadow: inset 0 0 0 1px $light30;
    }

    &.dx-state-focused {
      box-shadow: inset 0 0 0 1px $ui-primary;
    }

    &.dx-state-readonly {
      box-shadow: inset 0 0 0 1px $light30;

      &.dx-state-hover {
        box-shadow: inset 0 0 0 1px $light30;
      }

      .dx-texteditor-input {
        color: $light30 !important;
      }
    }
  }

  /*.dx-overlay .dx-invalid-message {
    display: none !important;
  }*/

  .dx-invalid.dx-texteditor.dx-show-invalid-badge .dx-texteditor-input-container:after {
    display: none;
  }

  .dx-button-has-icon .dx-icon {
    width: 20px;
    height: 20px;
  }

  /* Remove background and border for the button */
  .dx-button {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }

  /* Remove the hover effect */
  .dx-button:hover {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }

  /* Remove the active effect */
  .dx-button:active {
    background-color: transparent !important;
    box-shadow: none !important;
  }

  /* Optional: Adjust icon size or color */
  .dx-icon {
    font-size: 20px; /* Adjust as needed */
    color: #000; /* Set your desired color */
  }

  .dx-invalid-message {
    display: none;
  }

  .error-message {
    margin-top: 4px;
    font-size: 12px;
    line-height: 16px;
    color: $status_light_error;
    text-align: right;
  }

  .dx-placeholder {
    font-size: 14px;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    font-size: 14px;
    //-webkit-box-shadow: 0 0 0 30px white inset !important; // Change 'white' to your desired background color
    //-webkit-text-fill-color: #000 !important; // Change '#000' to your desired text color
    transition: background-color 5000s ease-in-out 0s;
    border-radius: 100px !important;
  }
}