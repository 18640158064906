$appTextSize: 12px;
$appBorderRadius: 12px;
$text-color: #333;
$label-color: #777;
$light-label-color: #8e8e93;
$border-color: #e0e0e0;
$acc-border-color: #efeff4;
$acc-background: #f2f5fe;
$grey-text-bg: #f8f8f8;
$grid-col-red: #ff3b30;
$grid-col-green: #33b84a;
$grid-col-lightgreen: #4cd964;
$yellow: #FFFD82;
$grid-col-orange: #ff9500;
$main-blue: #007AFF;
$grid-col-blue: #0077fa;

$def60: #4D5256;
$def-light-20: #EDEFF2;

$ui-primary: #007AFF;
$ui-primary-pressed: #0066FF;
$ui-light-10: #F3F5FD;

$light90: #1D1F20;
$light80: #26292B;
$light70: #303336;
$light60: #4D5256;
$light50: #696F77;
$light40: #888E96;
$light30: #C3CAD2;
$status_light_error: #F24236;
$dark-grey: #888E96;
$light-grey: #e0e3e7;
$main-yellow: #FFC700;
$secondary-yellow: #FFFDE9;