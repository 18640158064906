.success-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;

	.animation-wrapper {
		text-align: center;
		width: 120px;
		position: relative;

		#animation {
			display: inline-block;
			transition: all 1s ease-out;
		}

		#success {
			position: absolute;
			left: 0;
			top: 0;
			display: inline-block;
			transition: all 2s ease-in;
			opacity: 0;
		}
	}

	#status-text {
		font-size: 20px;
		display: inline-block;
		margin-top: 15px;
	}
}