$grey-color: #efeff4;

.verification-wrapper {
	height: 100%;

	.verification-head {

		.verification-header_text {
			padding: 20px;
			font-weight: bold;
			line-height: 28px;
			border-bottom: 2px solid $grey-color;

			i {
				width: auto;
				height: 30px;
				background: #4cd964;
				color: #fff;
				border-radius: 6px;
				padding: 7px;
				margin-right: 10px;
				font-size: 15px;
			}
		}

		.verification-progress-bar {
			//display: flex;
			display: none;
			flex-direction: column;

			.verification-progress-row {
				display: flex;
				border-bottom: 2px solid $grey-color;

				.verification-progress-item {
					width: 50%;
					height: 100px;
					display: flex;
					align-items: center;
					padding: 20px;

					.progress {
						width: 60px;
						height: 60px;
						display: flex;
						flex-shrink: 0;
						align-items: center;
						justify-content: center;
						border-radius: 12px;
						box-shadow: 0 4px 27px 0 rgba(0, 0, 128, 0.08);
						margin-right: 10px;
					}

					.progress-text {
						display: flex;
						flex-direction: column;

						.progress-text-title {
							font-size: 15px;
							line-height: 15px;
							font-weight: 600;
							margin-bottom: 10px;
						}

						.progress-text-description {
							font-size: 12px;
							line-height: 15px;
						}
					}
				}
			}
		}
	}

	.verification-body {
		height: 100%;

		.verification-body-wrapper {
			height: 100%;

			.verification-in-progress {
				text-align: center;
				margin-top: 50px;

				p {
					font-weight: 500;
					line-height: 22.4px;
					font-size: 14px;
					color: #232425;
					margin: 0;
					font-family: 'Montserrat', sans-serif;
				}
			}

			.verification-iframe {
				height: 100%;
				flex-direction: column;
				display: flex;
				justify-content: center;
				align-items: center;

				#sumsub-websdk-container {
					width: 100%;
					height: 100%;
					overflow: auto;
					padding-bottom: 20px;
				}
			}

			.verification-init {
				margin-top: 50px;
				flex-direction: column;
				display: flex;
				justify-content: center;
				align-items: center;

				.verification-logo {
					width: 120px;
					margin: 30px 0 15px 0;
				}

				.verification-access-text {
					width: 100%;
					max-width: 450px;
					text-align: center;
					font-size: 14px;
					margin-bottom: 10px;
				}

				.verification-checkbox-wrapper {
					display: flex;
					flex-direction: column;
					align-items: center;

					.verification-checkbox {
						width: 400px;
						align-items: center;

						.dx-validator {
							line-height: 34px;
							margin-right: 10px;
						}

						.dx-checkbox-icon {
							margin-right: 5px;
							border-radius: 20px;
						}
					}
				}
			}
		}
	}
}

circle {
	transition: stroke-dashoffset 0.35s;
	transform: rotate(-90deg);
	transform-origin: 50% 50%;
}
