#root {
	display: flex;
	flex-direction: column;
	height: 100%;

	.dx-popup-content {
		position: relative;

		.flexi-popup-close {
			position: absolute;
			top: 10px;
			right: 10px;
			cursor: pointer;
			width: 20px;
			height: 20px;
			line-height: 20px;

			i {
				font-size: 20px;
				color: #d7d7d7;
			}
		}

		.flexi-popup-content {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 0;

			.flexi-popup-logo {
				width: 180px;
				margin: 30px 0 15px 0;
				text-align: center;
			}

			.flexi-popup-head {
				text-align: center;
				margin-bottom: 10px;

				.flexi-popup-title {
					font-size: 18px;
					font-weight: bold;
				}

				.flexi-popup-subtitle {
					font-size: 12px;
					color: #8e8e93;
				}
			}

			.flexi-popup-selects {
				padding: 20px;
				width: 100%;

				.flexi-popup-select_item {
					position: relative;
					margin-bottom: 20px;

					.flexi-popup-select_item__icon {
						width: 19px;
						position: absolute;
						top: 50%;
						left: 21px;
						margin-top: -10px;
					}
				}

				.dx-selectbox.dx-textbox, .dx-selectbox.dx-textbox.dx-state-focused {
					padding: 0 20px;
					border-radius: 25px;
					box-shadow: 0 4px 27px 0 rgba(0, 0, 128, 0.08);

					&:after {
						border: none;
					}
				}

				.dx-texteditor-input, .dx-placeholder {
					padding-left: 35px;
				}
			}
		}
	}
}
